<template>
  <!-- 供需关系 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('transport_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
    ></x-table>

    <transport-Model
      v-model="DriverModel"
      :title="DriverTitle"
      :DefluteValue="DefluteValue"
      :defaultEdeit="defaultEdeit"
      @refrestList="refrestList"
    ></transport-Model>
    <track-modal
      v-model="trackModalData.show"
      :order="trackModalData.order"
    ></track-modal>
  </div>
</template>

<script>
import transportModel from "./transportModel";
import trackModal from "./trachModal.vue";

export default {
  name: "",
  components: {
    transportModel,
    trackModal,
  },
  data() {
    return {
      path: "",
      DriverModel: false,
      DriverTitle: "",
      DefluteValue: {},
      trackModalData: {
        show: false,
        order: null,
      },
      table: {
        columns: [
          {
            title: "司机姓名",
            minWidth: 120,
            key: "driverName",
            fixed: 'left'
          },
          {
            title: "车牌号",
            minWidth: 110,
            key: "carName",
            fixed: 'left'
          },
          {
            title: "司机联系电话",
            minWidth: 140,
            key: "driverPhone",
            fixed: 'left'
          },
          {
            title: "运输类型",
            minWidth: 100,
            render: (h, { row }) => {
              // if (!row.transportType) return;
              return (
                <span>
                  {
                    row.transportType == "0"
                      ? "供应订单"
                      : // row.transportType == "2" ?
                        "采购订单"
                    // : row.transportType == "3"
                    // ? "自填订单"
                    // : "其他"
                  }
                </span>
              );
            },
          },
          {
            title: "收货人姓名",
            minWidth: 150,
            key: "receiveName",
          },
          {
            title: "收货人电话",
            minWidth: 150,
            key: "receivePhone",
          },
          {
            title: "源地址",
            minWidth: 300,
            key: "originAddress",
            tooltip: true,
          },
          {
            title: "目的地",
            minWidth: 100,
            key: "transportAddress",
          },
          {
            title: "运输描述",
            minWidth: 100,
            key: "transportContent",
          },
          {
            title: '开始时间',
            width: 180,
            key: 'createTime'
          },
          {
            title: '结束时间',
            width: 180,
            key: 'endTime'
          },
          {
            title: "备注",
            minWidth: 100,
            key: "remark",
          },
          {
            title: "状态",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <span>{row.demandStatus == "1" ? "发布中" : "已处理"}</span>
              );
            },
          },
          {
            title: "操作",
            width: 300,
            fixed: 'right',
            render: (h, { row }) => {
              return (
                <div>
                  {row.transportStatus == 1 &&
                    this.path != "/transportHistory" && (
                      <Poptip
                        confirm
                        transfer
                        title="确定运输开始吗?"
                        on-on-ok={() => this.transportStart(row.id)}
                      >
                        <a style="margin-right: 10px">运输开始</a>
                      </Poptip>
                    )}
                  {row.transportStatus == 2 &&
                    this.path != "/transportHistory" && (
                      <Poptip
                        confirm
                        transfer
                        title="确定运输完成吗?"
                        on-on-ok={() => this.transportFinish(row.id)}
                      >
                        <a style="margin-right: 10px">运输完成</a>
                      </Poptip>
                    )}
                  {localStorage.userId == row.createUserId &&
                    this.CA("supply_finish") &&
                    this.path != "/transportHistory" && (
                      <a
                        style="margin-right: 10px"
                        onClick={() =>
                          row.demandStatus == "1"
                            ? this.finishSupply(row.id)
                            : ""
                        }
                      >
                        {row.demandStatus == "1" ? "发布中" : "已处理"}
                      </a>
                    )}
                  {this.CA("transport_edit") &&
                    this.path != "/transportHistory" && (
                      <a
                        style="margin-right: 10px"
                        onClick={() => this.edit(row)}
                      >
                        编辑
                      </a>
                    )}
                  {
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      运输详情
                    </a>
                  }

                  {row.transportStatus == "1" &&
                    this.CA("transport_delete") &&
                    this.path != "/transportHistory" && (
                      <Poptip
                        confirm
                        transfer
                        title="确定删除吗?"
                        on-on-ok={() => this.delete(row.id)}
                      >
                        <a>删除</a>
                      </Poptip>
                    )}
                  {row.transportStatus != "1" &&
                    this.path == "/transportHistory" && (
                      <a
                        style="margin-right: 10px"
                        onClick={() => this.checkTrack(row)}
                      >
                        行程追踪
                      </a>
                    )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          width: 200,
          filterBox: [
            {
              conditionName: "车牌号",
              component: "input",
              field: "plate",
              defaultValue: "",
            },

            {
              conditionName: "司机电话",
              component: "input",
              field: "driverPhone",
              defaultValue: "",
            },

            {
              conditionName: "收货人",
              component: "input",
              field: "receiveName",
              defaultValue: "",
            },
            {
              conditionName:"收货人电话",
              component:"input",
              field:"receivePhone",
              defaultValue:""
            },
            {
              conditionName: "目的地",
              component: "input",
              field: "transportAddress",
              defaultValue: "",
            },
            {
              conditionName: "源地址",
              component: "input",
              field: "originAddress",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      defaultEdeit: false,
      deptList: [],
      search_data: {},
    };
  },
  methods: {
    checkTrack(order) {
      this.trackModalData.order = order;
      this.trackModalData.show = true;
    },
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    refrestList() {
      this.getList();
    },
    getList() {
      if (!this.CA("transport_check")) return;
      this.table.loading = true;
      this.$post(this.$api.TRANSPORT.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        transportStatus: this.path == "/transportHistory" ? "2,3" : "",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      this.defaultEdeit = false;
      this.DriverModel = true;
      this.DriverTitle = "运输修改";
      this.DefluteValue = row;
    },
    checkInfo(row) {
      this.defaultEdeit = true;
      this.DefluteValue = row;
      this.DriverModel = true;
      this.DriverTitle = "运输详情";
    },
    transportStart(id) {
      this.$post(this.$api.TRANSPORT.START, {
        id,
      }).then(() => {
        this.$Message.success("运输开始");
        this.getList();
      });
    },
    transportFinish(id) {
      this.$post(this.$api.TRANSPORT.FINISH, {
        id,
      }).then(() => {
        this.$Message.success("运输完成");
        this.getList();
      });
    },
    //需求处理列表
    finishSupply(id) {
      this.$post(this.$api.SUPPLY_BIND.FINISH, { id: id })
        .then((res) => {
          this.$Message.success("处理成功");
          this.getList();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //删除
    delete(id) {
      this.$post(this.$api.TRANSPORT.DELETE, {
        id: id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    //获取部门列表
    getDeptList() {
      this.$post(this.$api.company.BARNCHTREE)
        .then((res) => {
          this.deptList = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.path = this.$route.path;
    this.getDeptList();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>